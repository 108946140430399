import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AuthGuard,
  DependentKillGuard,
  EmbassyGuard,
  GuestGuard,
  MaintenanceGuard,
  NotAdminGuard,
  NotMaintenanceGuard,
  PendingEmailGuard,
  PendingGuard,
  StagingGuard,
} from '@fengbrasil/ngx-fengst-auth';
import {
  AdminComponent,
  InternalServerErrorComponent,
  LogoutComponent,
  MaintenanceComponent,
  PageNotFoundComponent,
  PrivacyComponent,
  RecoveryPasswordComponent,
  ScailingMessageComponent,
  TermsComponent,
} from '@fengbrasil/ngx-fengst-layout';
import { EmbedFormsComponent } from './embed-forms/embed-forms.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [
      AuthGuard,
      MaintenanceGuard,
      PendingGuard,
      
      DependentKillGuard,
    ],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [MaintenanceGuard, DependentKillGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'minha-conta/dependentes',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'minha-conta/meu-kit',
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.MyKitModule),
    canActivate: [MaintenanceGuard, AuthGuard,  DependentKillGuard ]
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full',
  },
  {
    path: 'checkout/register',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.RegisterModule),
    canActivate: [MaintenanceGuard, GuestGuard],
  },
  {
    path: 'checkout/success',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.SuccessModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'checkout/subscribe/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.SubscribeModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then(
        (m) => m.ResubscribeContractModule
      ),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'checkout/resubscribe/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then(
        (m) => m.ResubscribeModule
      ),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'checkout/upgrade/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.UpgradeModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'checkout/address',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.AddressModule),
    canActivate: [
      MaintenanceGuard,
      AuthGuard,
      PendingGuard,
      
      PendingEmailGuard,
    ],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then(
        (m) => m.ValidateAccountModule
      ),
    canActivate: [MaintenanceGuard],
  },
  //Gift Cart - Carrinho
  {
    path: 'checkout/cart-gift/:type/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartGiftModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'checkout/gift/subscribe/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then(
        (m) => m.SubscribeGiftModule
      ),
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard],
  },
  {
    path: 'checkout/cart/:type/:idplano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'checkout/cart',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'planos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-checkout').then((m) => m.PlansModule),
    canActivate: [MaintenanceGuard, DependentKillGuard],
  },
  //Gift Card
  {
    path: 'presente',
    canActivate: [MaintenanceGuard],
    data: {
      // meta: {
      //   robots: 'index, follow',
      //   title: 'Adote um Sócio, Gigante!',
      //   override: false,
      //   description:
      //     'É hora de mostrar porque o sentimento não para. O motivo de termos o maior plano de sócios do Brasil.',
      // },
    },
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-gift').then((m) => m.GiftCardModule),
  },
  {
    path: 'presente/cadastro',
    canActivate: [MaintenanceGuard],
    data: {
      // meta: {
      //   robots: 'index, follow',
      //   title: 'Adote um Sócio, Gigante!',
      //   override: false,
      //   description:
      //     'É hora de mostrar porque o sentimento não para. O motivo de termos o maior plano de sócios do Brasil.',
      // },
    },
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-gift').then(
        (m) => m.GiftCardRegisterModule
      ),
  },
  {
    path: 'sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'conteudo-exclusivo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-exclusive-content').then(
        (m) => m.MainModule
      ),
    canActivate: [MaintenanceGuard, PendingGuard, DependentKillGuard],
  },
 /*  {
    path: 'welcome-kit',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.WelcomeKitModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  }, */
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [
      MaintenanceGuard,
      PendingGuard,
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
    canActivate: [MaintenanceGuard, PendingGuard, DependentKillGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [
      MaintenanceGuard,
      PendingGuard,
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [
      MaintenanceGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [
      MaintenanceGuard,
      PendingGuard,
      
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [
      MaintenanceGuard,
      PendingGuard,
      DependentKillGuard,
      PendingEmailGuard,
    ],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'embaixadas',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.EmbassiesModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'minha-embaixada',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.MyEmbassyModule),
      canActivate: [ AuthGuard, MaintenanceGuard],
  },
  {
    path: 'embaixadas/crie-a-sua',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.CreateEmbassyModule),
      canActivate: [ AuthGuard, MaintenanceGuard],
  },
  {
    path: 'my-embassy/sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'st/sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'transparencia',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-xray').then((m) => m.XrayModule),
    canActivate: [MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'clube93',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingMainModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'clube93/cadastro',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then(
        (m) => m.LandingRegisterModule
      ),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'clube93/sucesso',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then(
        (m) => m.LandingSuccessModule
      ),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'termos-e-politicas',
    component: TermsComponent,
  },
  {
    path: 'aviso-de-privacidade',
    component: PrivacyComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [NotAdminGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'WE',
    component: MaintenanceComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: 'https://admin.stg.spfc.feng.rocks/WE',
    },
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent,
  },
  {
    path: '500',
    component: InternalServerErrorComponent,
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'pesquisa',
    component: EmbedFormsComponent,
    canActivate: [MaintenanceGuard, AuthGuard, PendingGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
